<template>
  <header class="fixed z-10 top-0 inset-x-0 bg-white bg-opacity-80 backdrop-blur-lg">
    <div class="container">
      <div class="py-15 lg:py-25 flex justify-between">
        <nav class="flex">
          <router-link
            :to="{ path: '/', hash: '#home' }"
            class="mr-20 xl:mr-50 z-20"
            aria-label="Naar home"
          >
            <img
              src="/img/logo.svg"
              alt="Clubee"
              title="Clubee"
              class="w-[125px] lg:w-auto"
            >
          </router-link>

          <ul
            :class="{ 'opacity-0 invisible': !menuOpen }"
            class="transition-all max-lg:absolute max-lg:top-0 max-lg:inset-x-0 max-lg:pt-100 max-lg:pb-40 max-lg:px-15 max-lg:gap-25 max-lg:flex-col max-lg:h-auto max-lg:shadow-lg max-lg:bg-white max-lg:justify-center max-lg:items-start max-lg:text-lg lg:opacity-100 lg:visible flex gap-20 text-base xl:gap-x-50 lg:items-center font-medium text-gray-500"
          >
            <li
              v-for="item in menuItems"
              :key="`menu-item-${item.hash}`"
              :class="[{ 'z-[1]': item.marker }, 'group relative']"
            >
              <component
                :is="item.children ? 'span' : 'router-link'"
                :to="item.children ? null : { hash: item.hash, path: '/' }"
                class="max-lg:underline hover:no-underline hover:text-black transition inline-flex items-center gap-x-10"
                @click="menuOpen = !menuOpen"
                @keydown.enter="menuOpen = !menuOpen"
              >
                <span :class="{ 'lg:marker lg:marker-sm': item.marker }">{{ item.title }}</span>
                <svg
                  v-if="item.children"
                  class="max-lg:hidden"
                  width="13"
                  height="7"
                  viewBox="0 0 13 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3534 0.852292C12.3533 0.682675 12.3024 0.516937 12.2071 0.376275C12.1118 0.235612 11.9764 0.126421 11.8183 0.0626672C11.6603 -0.00108636 11.4867 -0.0165043 11.3197 0.0183858C11.1528 0.0532759 11.0001 0.136888 10.8813 0.258527L6.17394 4.94103L1.46494 0.260226C1.38524 0.180994 1.29063 0.118148 1.18651 0.0752753C1.08239 0.0324028 0.970798 0.0103441 0.858103 0.0103589C0.745409 0.0103737 0.633819 0.0324615 0.529709 0.0753613C0.425599 0.118261 0.331008 0.181133 0.251335 0.260386C0.171661 0.339639 0.108469 0.43372 0.0653632 0.53726C0.0222586 0.640799 8.56952e-05 0.751767 0.000111643 0.863827C0.000136638 0.975887 0.0223611 1.08684 0.0655144 1.19036C0.108667 1.29388 0.171903 1.38794 0.251613 1.46715L5.5675 6.7512C5.64714 6.83051 5.74174 6.89343 5.84586 6.93635C5.94998 6.97927 6.0616 7.00135 6.17431 7.00133C6.28703 7.00131 6.39864 6.97919 6.50275 6.93623C6.60685 6.89327 6.70142 6.83032 6.78104 6.75098L12.095 1.46502C12.1771 1.38528 12.2423 1.28997 12.2867 1.18472C12.3311 1.07946 12.3538 0.966422 12.3534 0.852292Z"
                    fill="#888888"
                  />
                </svg>
              </component>
              <ul
                v-if="item.children"
                class="max-lg:space-y-15 pl-10 mt-25 space-y-20 text-md lg:text-base lg:absolute lg:my-0 lg:top-40 lg:p-10 lg:space-y-2 lg:rounded-xl lg:border lg:bg-white lg:transition lg:opacity-0 lg:invisible group-hover:opacity-100 group-hover:visible before:absolute before:-top-40 before:h-40 before:inset-x-0"
              >
                <li
                  v-for="child in item.children"
                  :key="`child-item-${child.path}`"
                >
                  <router-link
                    :to="child.path"
                    class="max-lg:underline hover:no-underline hover:text-black hover:bg-neutral-100 transition flex items-center lg:py-8 lg:px-15 gap-x-12 rounded-lg"
                  >
                    <svg
                      width="9"
                      height="15"
                      viewBox="0 0 9 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.11443 0.365727C0.917525 0.365777 0.725106 0.424563 0.56179 0.534568C0.398474 0.644573 0.271684 0.800795 0.197639 0.983252C0.123594 1.16571 0.105658 1.36611 0.146128 1.55881C0.186597 1.75152 0.283631 1.92777 0.424818 2.06503L5.8598 7.5L0.424818 12.935C0.33282 13.027 0.259841 13.1362 0.210048 13.2563C0.160255 13.3765 0.134624 13.5053 0.134617 13.6354C0.134611 13.7655 0.160229 13.8944 0.21001 14.0145C0.259791 14.1347 0.33276 14.2439 0.424748 14.3359C0.516737 14.4279 0.625945 14.5009 0.746135 14.5507C0.866325 14.6004 0.995145 14.6261 1.12524 14.6261C1.25533 14.6261 1.38415 14.6004 1.50433 14.5506C1.62452 14.5008 1.73372 14.4279 1.8257 14.3359L7.96111 8.20044C8.0532 8.10852 8.12626 7.99934 8.17611 7.87915C8.22596 7.75896 8.25161 7.63012 8.25161 7.5C8.25161 7.36989 8.22596 7.24105 8.17611 7.12086C8.12626 7.00067 8.0532 6.89149 7.96111 6.79957L1.8257 0.664148C1.73315 0.569328 1.62251 0.494056 1.50033 0.442794C1.37816 0.391532 1.24693 0.365325 1.11443 0.365727Z"
                        fill="currentColor"
                      />
                    </svg>
                    {{ child.title }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link
                :to="{
                  hash: '#contact',
                  path: '/',
                }"
                class="flex lg:hidden items-center text-base max-lg:no-underline text-black whitespace-nowrap px-20 h-50 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
                @click="menuOpen = !menuOpen"
                @keydown.enter="menuOpen = !menuOpen"
              >
                Demo aanvragen
              </router-link>
            </li>
          </ul>
        </nav>

        <ul class="z-10 flex space-x-10 ml-10 items-center font-semibold text-black">
          <li>
            <router-link
              :to="{ hash: '#contact', path: '/' }"
              class="max-xl:hidden flex items-center text-sm lg:text-base whitespace-nowrap px-15 lg:px-20 h-40 lg:h-50 rounded-full bg-gradient-to-r from-primary-400 via-primary-500 to-primary-400 bg-size-200 bg-pos-0 hover:bg-pos-100 hover:no-underline transition-all"
            >
              Demo aanvragen
            </router-link>
          </li>
          <li>
            <a
              href="https://admin.clubee.nl/"
              title="Inloggen"
              class="flex items-center justify-center size-40 lg:size-50 rounded-full bg-black bg-opacity-5 hover:bg-opacity-10 transition"
            >
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Inloggen</title>
                <path
                  d="M9 13v2m-6 4h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2zM13 9V5a4 4 0 0 0-8 0v4h8z"
                  stroke="#000"
                  stroke-width="2"
                  fill="none"
                  fill-rule="evenodd"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </li>
          <li
            class="flex flex-col items-center bg-gradient-to-r from-primary-400 to-primary-500 size-40 justify-center rounded-full lg:hidden"
            @click="menuOpen = !menuOpen"
            @keydown.enter="menuOpen = !menuOpen"
          >
            <span
              :class="{ 'rotate-45 !-mb-[1px]': menuOpen }"
              class="inline-block bg-black h-2 w-20 mb-3 transition-all"
            />
            <span
              :class="{ '-rotate-45 !-mt-[1px]': menuOpen }"
              class="inline-block bg-black h-2 w-20 mt-3 transition-all"
            />
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      menuOpen: false,
      menuItems: [
        { title: 'Wat is Clubee?', hash: '#club' },
        { title: 'Voordelen voor leden', hash: '#leden' },
        { title: 'Tarieven', hash: '#tarieven' },
        { title: 'Contact', hash: '#contact', marker: true },
        {
          title: 'Voor wie',
          children: [
            { title: 'Businessclubs', path: '/businessclubs' },
            { title: 'Verenigingen', path: '/verenigingen' },
            { title: 'Sportclubs', path: '/sportclubs' },
          ],
        },
      ],
    };
  },
});
</script>
