import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/routes';

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 80,
      };
    }

    if (!from.name || savedPosition?.top) {
      return savedPosition;
    }

    return { top: 0 };
  },
});
