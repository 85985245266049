import Home from '@/views/Home.vue';
import BusinessClubs from '@/views/BusinessClubs.vue';
import Associations from '@/views/Associations.vue';
import SportClubs from '@/views/SportClubs.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/businessclubs',
    name: 'businessclubs',
    component: BusinessClubs,
  },
  {
    path: '/verenigingen',
    name: 'verenigingen',
    component: Associations,
  },
  {
    path: '/sportclubs',
    name: 'sportclubs',
    component: SportClubs,
  },
];
