import { createApp } from 'vue';
import gtm from '@/plugins/gtm';
import App from '@/App.vue';
import router from '@/plugins/router';
import '@/assets/css/styles.css';

const app = createApp(App)
  .use(router)
  .use(gtm);

router.isReady().then(() => {
  app.mount('#app');
});
